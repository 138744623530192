import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "CapituloUnoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "CapituloUnoPage", 
            ss: ss,
            ajax: {
                "url": ss.indexArchivoCapitulo4(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                }
            },
            columns: [
                { data: 'idEspecialidad', name: 'idEspecialidad', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'NivelAcad', name: 'NivelAcad', title: 'Nivel Académico' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Cargar Archivo',
                    searchable: false
                },
            ],
            especialidad:{},
            usuario:{},
            competenciaProfesionalSelect:{},
            competenciaGeneral:{},
            competenciaArea:{},
            competenciaEspecifica:{},
            competenciaInstrumental:{},
            competenciasArea:[],
            competenciasEspecificas:[],
            competenciasInstrumentales:[],
            competenciaInstrumentalCCP:[],
            especialidadesSAGA:[],
            nombreEspecialidad:'',
            isLoading: false,
            isLoadingFile: false,
            isPropuesta: false,
            observacion:{},
            observaciones:[],
            detalle:{},
            observacionDetalles:[],
            observacionDetalle:{},
            observacionSelect:{},
            url:'',
            url2:'',
            urlArchivo:'',
            urlArchivo2:'',
            errorBag: {},
            archivo:{},
            archivo2:{},
            password: {}
        };
    },
    methods: {
        
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {

                const fileInput = event.target.files[0];
                const fileType = fileInput.type;

                if (fileType !== 'application/pdf') {

                    this.$swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Solo se permiten archivos PDF!",
/*                         footer: '<a href="#">Why do I have this issue?</a>'
 */                      });
                    this.isLoadingFile = false;
                    return;
                }

                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            
                            this.isLoadingFile = false;
                            this.archivo.capiv= result.data.data;
                            console.log({
                                archresultadoCap1: this.archivo,
                                resultadoCap1: this.archivo.capiv
                            })
                            this.storearchivo(this.archivo)
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            ) 
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        loadFile2(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            
                            this.isLoadingFile = false;
                            this.archivo2.CapII= result.data.data;
                            console.log({
                                archresultadoCap2: this.archivo2,
                                resultadoCap2: this.archivo2.CapII
                            })
                            this.storearchivo2(this.archivo2)
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            ) 
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        showarchivo(id){
            
            //this.isLoading = true;
            
            this.ss.showarchivo(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data==null)
                    {
                        let item={};
                        item.idEspecialidad=id;
                        this.storearchivo(item);
                    }
                   else{
                    this.archivo=response.data;
                   }
                   
                    console.log(this.url);
                    console.log(this.archivo);
                    this.urlArchivo=this.url+this.archivo.capiv;
                    console.log(this.urlArchivo);
                    //this.isLoading = false;
                    this.$refs['view-FormularioObservacion'].show();
                }
            ).catch(error => {
                console.log(error);
            });
            
        },

        /*unirPdf (id) {
            console.log('dd',id);
            this.ss.unirPdf(id).then(
                ( result ) => {
                    console.log({result});
                    
                    
                   // console.log('holaaaa',{ result}) ;
                    //console.log('holaaaa',response.data);
            
                }
            ).catch(error => {
                console.log(error);
            });
           
            
        },*/

        unirPdf(id) {
            console.log('dd', id);
        
            this.ss.unirPdf(id).then((response) => {
                // Crear un objeto URL para el blob recibido
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        
                // Crear un enlace temporal para desencadenar la descarga
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'union.pdf'); // Nombre del archivo descargado
        
                // Añadir el enlace al DOM, hacer clic y eliminarlo
                document.body.appendChild(link);
                link.click();
                link.remove();
        
                // Limpiar el objeto URL
                window.URL.revokeObjectURL(url);
            }).catch(error => {
                console.error('Error al descargar el PDF', error);
            });
        },

        storearchivo(id)
        {
            this.ss.storearchivo(id).then(
                (result) => {
                    let response = result.data;
                   
                    console.log(response.data);
                    this.archivo=response.data;
                    //console.log(this.observaciones);
                    //this.isLoading = false;
                    //this.$refs['view-FormularioObservacion'].show();
                    this.urlArchivo=this.url+this.archivo.capiv;
                }
            ).catch(error => {
                console.log(error);
            });
        },
     

        showarchivo2(id){
            
            //this.isLoading = true;
            
            this.ss.showarchivo2(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data==null)
                    {
                        let item={};
                        item.idEspecialidad=id;
                        this.storearchivo2(item);
                    }
                   else{
                    this.archivo2=response.data;
                   }
                   
                    console.log(this.url2);
                    console.log(this.archivo2);
                    this.urlArchivo2=this.url+this.archivo2.capiv;
                    console.log(this.urlArchivo2);
                    //this.isLoading = false;
                    this.$refs['view-FormularioObservacion2'].show();
                }
            ).catch(error => {
                console.log(error);
            });
            
        },

        storearchivo2(id)
        {
            this.ss.storearchivo2(id).then(
                (result) => {
                    let response = result.data;
                   
                    console.log(response.data);
                    this.archivo2=response.data;
                    //console.log(this.observaciones);
                    //this.isLoading = false;
                    //this.$refs['view-FormularioObservacion2'].show();
                    this.urlArchivo2=this.url2+this.archivo2.capiv;
                    console.log(this.urlArchivo2);
                }
            ).catch(error => {
                console.log(error);
            });
        },
     
        cargarFormulario(item) {
            this.observacionSelect=item;
            console.log('OBSERVACION SELECT');
            console.log(this.observacionSelect);
            this.urlArchivo = this.url + this.observacionSelect.urlObservacion;
            console.log(this.urlArchivo);
            this.$refs['view-FormularioObservacion'].show();
        },
        async getarchivo(){
            this.isLoading=true;
            console.log('promesa');
            await new Promise(resolve => setTimeout(resolve, 2000));

            if(this.usuario.idEspecialidad){
                let id = this.usuario.idEspecialidad;
                this.ajax.data = function(d){
                    d.idEspecialidad = id;
                }
                this.$refs['datatable-ArchivoCapituloI'].reloadAjax(this.ajax);
            }
            this.isLoading=false;
        }, 
        
        async getEspecialidad(){
            this.isLoading=true;
            let idEspecialidad = this.usuario.idEspecialidad;
            let idUnidadAcademica = this.usuario.UnidadAcademica;
            this.ajax.data = function(d){
                d.idEspecialidad = idEspecialidad;
                d.idUnidadAcademica = idUnidadAcademica;
            }
            await new Promise(resolve => setTimeout(resolve, 2000));
            this.$refs['datatable-ArchivoCapitulo'].reloadAjax(this.ajax);
            this.isLoading=false;
       },
        
        draw() {
            window.$('.btn-datatable-ArchivoCapituloI').on('click', (evt) => {
                let idEspecialidad = window.$(evt.target)[0].getAttribute('idEspecialidad');
               console.log('capituloI '+ idEspecialidad);
                if(idEspecialidad){
                    this.showarchivo(idEspecialidad);
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });

            window.$('.btn-datatable-ArchivoCapituloII').on('click', (evt) => {
                let idEspecialidad = window.$(evt.target)[0].getAttribute('idEspecialidad');
               console.log('capituloII '+ idEspecialidad);
                if(idEspecialidad){
                    this.showarchivo2(idEspecialidad);
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });

            window.$( '.btn-datatable-unirpdf' ).on( 'click', ( evt ) => {
                let idEspecialidad = window.$(evt.target)[0].getAttribute('idEspecialidad');

                console.log('unirpdf xd', idEspecialidad);
                
                
                    this.unirPdf(idEspecialidad);
                
                    /*this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )*/
                
            } );
        }
       
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.usuario = persona;
            console.log('logeado');
            console.log(persona);
/*             this.getarchivo();
            this.getEspecialidades(); */
            this.url = process.env.VUE_APP_MAIN_SERVICE + 'storage/documents/';
            this.url2 = process.env.VUE_APP_MAIN_SERVICE + 'storage/documents/';
            this.getEspecialidad();

        }
    }
};
