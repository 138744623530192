import { render, staticRenderFns } from "./CapituloTresPage.vue?vue&type=template&id=621f1eb4"
import script from "./CapituloTresPage.js?vue&type=script&lang=js&external"
export * from "./CapituloTresPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports