import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PensumsPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PensumsPage",
            ss: ss,
            ajax: {
                "url": ss.indexEspecialidades(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                }
            },
            columns: [
                { data: 'idEspecialidad', name: 'idEspecialidad', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Especialidad', name: 'Especialidad', title: 'Especialidad' },
                { data: 'NivelAcad', name: 'NivelAcad', title: 'Nivel Académico' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            especialidad:{},
            usuario:{},
            competenciaProfesionalSelect:{},
            competenciaGeneral:{},
            competenciaArea:{},
            competenciaEspecifica:{},
            competenciaInstrumental:{},
            competenciasArea:[],
            competenciasEspecificas:[],
            competenciasInstrumentales:[],
            competenciaInstrumentalCCP:[],
            especialidadesSAGA:[],
            nombreEspecialidad:'',
            isLoading: false,
            isLoadingFile: false,
            isPropuesta: false,
            errorBag: {},
            password: {}
        };
    },
    methods: {
        
        criterioSeleccion(item){
            this.competenciaProfesionalSelect = item;
            this.showCompetenciaEspecifica(item.id);
        },

    showCompetenciaEspecifica(id){
        this.ss.showCompetenciaEspecifica(id).then(
            (result) => {
                let response = result.data;
                if(response.data != null){
                    this.competenciasEspecificas = response.data;
                }else{
                    this.competenciasEspecificas = [];
                }
                console.log(this.competenciasEspecificas);
            }
        ).catch(error => {
            console.log(error);
        });
    },

        storeCompetenciaProfesional(item) {
            if(this.competenciaGeneral.id){
                if(item){
                    this.competenciaArea = item;
                }
                else{
                    this.competenciaArea = {};
                }
                this.$refs['frm-Competencia-Profesional'].show();
            }else{
                alert('Competencia General No Creada !');
            }
        },

        storeCompetenciaInstrumental(item){
            if(item){
                this.competenciaInstrumental = item;
            }
            this.$refs['frm-Competencias-Instrumentales'].show();

            //storeCompetenciaInstrumentales
        },

        saveCompetenciaInstrumental(){
            
            console.log(this.competenciaInstrumental);
            this.competenciaInstrumental.CompetenciaProf = "";
            for (let index = 0; index < this.competenciaInstrumental.CompetenciaProf1.length; index++) {
                this.competenciaInstrumental.CompetenciaProf += this.competenciaInstrumental.CompetenciaProf1[index] + ",";
            }
            this.competenciaInstrumental.competencias_general_id = this.competenciaGeneral.id;
            console.log(this.competenciaInstrumental);

            this.ss.storeCompetenciaInstrumentales(this.competenciaInstrumental).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['frm-Competencias-Instrumentales'].hide();
                        this.showCompetenciasInstrumentales(this.competenciaGeneral.id);
                    }
                    else{
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });

        },

        cancelStoreCompetenciaInstrumental(){
            this.$refs['frm-Competencias-Instrumentales'].hide();
        },

        cancelStoreProfesional(){
            this.$refs['frm-Competencia-Profesional'].hide();
        },

        StoreEspecifica(item){
            if(this.competenciaProfesionalSelect.CodigoArea){
                if(item){
                    this.competenciaEspecifica = item;
                }else{
                    this.competenciaEspecifica = {};
                }
                this.$refs['frm-Competencia-Especifica'].show();
            }else{
                alert('Competencia Profesional No Seleccionada !');
            }
        },

        cancelStoreEspecifica(){
            this.$refs['frm-Competencia-Especifica'].hide();
        },

        eliminarCompetenciaProfesionalPorArea(item){
              const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                  confirmButton: 'btn-success',
                  cancelButton: 'btn-danger'
                },
                buttonsStyling: true
              });
              
              swalWithBootstrapButtons.fire({
                title: 'Desea Eliminar Esta Competencia por Area ?',
                text: "Esta Accion es Irreversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminar !',
                cancelButtonText: 'No, Cancelar !',
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                    this.ss.destroyCompetenciaArea(item).then(
                        (result) => {
                            let response = result;
                            console.log(response.data);
                            this.showCompetenciaArea(this.competenciaGeneral.id);
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    }); 
                  swalWithBootstrapButtons.fire(
                    'Eliminado !',
                    'Resgistro Eliminado.',
                    'success'
                  );
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'Sin Registros Eliminados.',
                    'error'
                  );
                }
              });
        },

        EliminarEspecifica(item){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                  confirmButton: 'btn-success',
                  cancelButton: 'btn-danger'
                },
                buttonsStyling: true
              });
              
              swalWithBootstrapButtons.fire({
                title: 'Desea Eliminar Esta Competencia por Especifica ?',
                text: "Esta Accion es Irreversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminar !',
                cancelButtonText: 'No, Cancelar !',
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                    this.ss.destroyCompetenciaEspecifica(item).then(
                        (result) => {
                            let response = result;
                            console.log(response.data);
                            this.showCompetenciaEspecifica(this.competenciaProfesionalSelect.id);
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    }); 
                  swalWithBootstrapButtons.fire(
                    'Eliminado !',
                    'Resgistro Eliminado.',
                    'success'
                  );
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'Sin Registros Eliminados.',
                    'error'
                  );
                }
              });
        },

        eliminarCompetenciaInstrumental(item){
            const swalWithBootstrapButtons = this.$swal.mixin({
                customClass: {
                  confirmButton: 'btn-success',
                  cancelButton: 'btn-danger'
                },
                buttonsStyling: true
              });
              
              swalWithBootstrapButtons.fire({
                title: 'Desea Eliminar Esta Competencia por Instrumental ?',
                text: "Esta Accion es Irreversible !",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Eliminar !',
                cancelButtonText: 'No, Cancelar !',
                reverseButtons: true
              }).then((result) => {
                if (result.isConfirmed) {
                    this.ss.destroyCompetenciaInstrumentales(item).then(
                        (result) => {
                            let response = result;
                            console.log(response.data);
                            this.showCompetenciasInstrumentales(this.competenciaGeneral.id);
                        }
                    ).catch(error => {
                        console.log(error);
                        this.isLoading=false;
                    }); 
                  swalWithBootstrapButtons.fire(
                    'Eliminado !',
                    'Resgistro Eliminado.',
                    'success'
                  );
                  
                } else if (result.dismiss === this.$swal.DismissReason.cancel) {
                  swalWithBootstrapButtons.fire(
                    'Cancelado',
                    'Sin Registros Eliminados.',
                    'error'
                  );
                }
              });
        },

        showEspecialidad(id){
            this.ss.showEspecialidad(id).then(
                (result) => {
                    let response = result.data;
                    this.especialidad = response[0];
                    console.log(this.especialidad);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showCompetenciasInstrumentales(id){
            this.isLoading = true;
            this.ss.showCompetenciaInstrumentales(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.competenciasInstrumentales = response.data;

                        for (let index = 0; index < this.competenciasInstrumentales.length; index++) {
                            this.competenciaInstrumentalCCP = this.competenciasInstrumentales[index].CompetenciaProf.split(",");
                            this.competenciasInstrumentales[index].CompG = this.competenciaInstrumentalCCP;
                            
                        }
                        console.log(this.competenciasInstrumentales);
                    }
                    else{
                        this.competenciasInstrumentales=[];
                    }
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showCompetenciaGeneral(id){
            this.isLoading = true;
            this.ss.showCompetenciaGeneral(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.competenciaGeneral = response.data[0];
                        console.log(this.competenciaGeneral);
                        this.especialidad = this.competenciaGeneral;
                        this.showCompetenciaArea(this.competenciaGeneral.idEspecialidad);
                        this.showCompetenciasInstrumentales(this.competenciaGeneral.id);
                    }
                    else{
                        this.competenciaGeneral={};
                        this.competenciasArea={};
                        this.competenciasInstrumentales=[];
                        this.showEspecialidad(id); 
                    }
                    this.isLoading = false;
                    this.$refs['view-especialidad'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },


        showCompetenciaArea(id){
            this.ss.showCompetenciaArea(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.competenciasArea = response.data;
                    console.log(this.competenciasArea);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        async getEspecialidad2(){
            this.isLoading=true;
            console.log('promesa');
            await new Promise(resolve => setTimeout(resolve, 2000));

            if(this.usuario.idEspecialidad){
                let id = this.usuario.idEspecialidad;
                this.ajax.data = function(d){
                    d.idEspecialidad = id;
                }
                this.$refs['datatable-Especialidad'].reloadAjax(this.ajax);
            }
            this.isLoading=false;
        }, 

        storeCompetenciaEspecifica(){
            this.competenciaEspecifica.competencias_area_id = this.competenciaProfesionalSelect.id;
            this.competenciaEspecifica.CompetenciaEspecifica = this.competenciaEspecifica.CompetenciaEspecifica.replace(/\n/g, ' ');
            console.log(this.competenciaEspecifica);
            this.ss.storeCompetenciaEspecifica(this.competenciaEspecifica).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['frm-Competencia-Especifica'].hide();
                        this.showCompetenciaEspecifica(this.competenciaProfesionalSelect.id);
                    }
                    else{
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        storeCompetenciaArea(){
            this.competenciaArea.competencia_general_id = this.competenciaGeneral.id
            this.competenciaArea.Nombre = this.competenciaArea.Nombre.replace(/\n/g, ' ');
            this.competenciaArea.CompetenciaProfesional = this.competenciaArea.CompetenciaProfesional.replace(/\n/g, ' ');
            console.log(this.competenciaArea);
            this.ss.storeCompetenciaArea(this.competenciaArea).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                        this.$refs['frm-Competencia-Profesional'].hide();
                        this.showCompetenciaArea(this.competenciaGeneral.id);
                    }
                    else{
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        

        storeCompetenciaGeneral(){
            if(!this.competenciaGeneral.idEspecialidad){
                this.competenciaGeneral.idEspecialidad = this.especialidad.idEspecialidad;
            }
            let CompetenciaGeneralArreglado = this.competenciaGeneral.CompetenciaGeneral.replace(/\n/g, ' ');
            this.competenciaGeneral.CompetenciaGeneral = CompetenciaGeneralArreglado;
            console.log(this.competenciaGeneral);
            this.ss.storeCompetenciaGeneral(this.competenciaGeneral).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.competenciaGeneral = response.data;
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                    }
                    else{
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    }
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        getEspecialidades(){
            this.ss.listEspecialidad().then(
               (result) => {
                   let response = result.data;
                   console.log(response);
                   this.especialidadesSAGA = response;
               }
           ).catch(error => {
               console.log(error);
           });
        },

        showReporte(){

            if(this.usuario.idEspecialidad){
                let idEspecialidad = this.usuario.idEspecialidad;
                let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Materia/pdfCompetenciasGenerales?id=${idEspecialidad}`;
                window.open(url, '_blank');
                this.usuario.idEspecialidad=null;
                this.$refs['view-especialidad-select'].hide();
            }
            else{
                this.$refs['view-especialidad-select'].show();
            }
        },
       

        draw() {
            window.$('.btn-datatable-Especialidad').on('click', (evt) => {
                let idEspecialidad = window.$(evt.target)[0].getAttribute('idEspecialidad');
                if(idEspecialidad){
                    this.showCompetenciaGeneral(idEspecialidad);
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.usuario = persona;
            console.log('logeado');
            console.log(persona);
            this.getEspecialidad2();
            this.getEspecialidades();

        }
    }
};
