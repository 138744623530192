import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import CapituloUnoPage from '@/pages/CapituloUno/CapituloUnoPage.vue'
import CapituloDosPage from '@/pages/CapituloDos/CapituloDosPage.vue'
import CapituloTresPage from '@/pages/CapituloTres/CapituloTresPage.vue'
import CapituloCuatroPage from '@/pages/CapituloCuatro/CapituloCuatroPage.vue'
import CapituloCincoPage from '@/pages/CapituloCinco/CapituloCincoPage.vue'
import CapituloSeisPage from '@/pages/CapituloSeis/CapituloSeisPage.vue'
import CapituloSietePage from '@/pages/CapituloSiete/CapituloSietePage.vue'
import CapituloOchoPage from '@/pages/CapituloOcho/CapituloOchoPage.vue'
import CapituloNuevePage from '@/pages/CapituloNueve/CapituloNuevePage.vue'
import CapituloDiezPage from '@/pages/CapituloDiez/CapituloDiezPage.vue'
import CapituloOncePage from '@/pages/CapituloOnce/CapituloOncePage.vue'
import CapituloDocePage from '@/pages/CapituloDoce/CapituloDocePage.vue'
import CapituloTrecePage from '@/pages/CapituloTrece/CapituloTrecePage.vue'
import MateriaPage from '@/pages/Materia/MateriaPage.vue'
import ValidacionPage from '@/pages/Validacion/ValidacionPage.vue'
import AnexosPage from '@/pages/Anexos/AnexosPage.vue'
import PresentacionPage from '@/pages/Presentacion/PresentacionPage.vue'
import ImprimirPage from '@/pages/Imprimir/ImprimirPage.vue'


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'PrincipalPage',
      component: PrincipalPage
    },
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/CapituloUno',
      name: 'CapituloUno',
      component: CapituloUnoPage
    },
    {
      path: '/CapituloDos',
      name: 'CapituloDos',
      component: CapituloDosPage
    },
    {
      path: '/CapituloTres',
      name: 'CapituloTres',
      component: CapituloTresPage
    },
    {
      path: '/CapituloCuatro',
      name: 'CapituloCuatro',
      component: CapituloCuatroPage
    },
    {
      path: '/CapituloCinco',
      name: 'CapituloCinco',
      component: CapituloCincoPage
    },
    {
      path: '/CapituloSeis',
      name: 'CapituloSeis',
      component: CapituloSeisPage
    },
    {
      path: '/CapituloSiete',
      name: 'CapituloSiete',
      component: CapituloSietePage
    },
    {
      path: '/CapituloOcho',
      name: 'CapituloOcho',
      component: CapituloOchoPage
    },
    {
      path: '/CapituloNueve',
      name: 'CapituloNueve',
      component: CapituloNuevePage
    },
    {
      path: '/CapituloDiez',
      name: 'CapituloDiez',
      component: CapituloDiezPage
    },
    {
      path: '/CapituloOnce',
      name: 'CapituloOnce',
      component: CapituloOncePage
    },
    {
      path: '/CapituloDoce',
      name: 'CapituloDoce',
      component: CapituloDocePage
    },
    {
      path: '/CapituloTrece',
      name: 'CapituloTrece',
      component: CapituloTrecePage
    },
    {
      path: '/Materia',
      name: 'Materia',
      component: MateriaPage
    },
    {
      path: '/Validacion',
      name: 'Validacion',
      component: ValidacionPage
    },
    {
      path: '/Anexos',
      name: 'Anexos',
      component: AnexosPage
    },
    {
      path: '/Presentacion',
      name: 'Presentacion',
      component: PresentacionPage
    },
    {
      path: '/Imprimir',
      name: 'Imprimir',
      component: ImprimirPage
    }
  ]
})