<template>
    <v-list nav dense>
        <v-list-item-group>

          <router-link
                class="black--text text--accent-4"
                to="Profile"
                active-class="blue--text text--accent-4"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Profile")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>
        </v-list-item-group>


            <router-link
                class="black--text text--accent-4"
                to="Persona"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-account-multiple</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Persona")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            <!-- <router-link
                class="black--text text--accent-4"
                to="UnidadAcademica"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-school</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.UnidadAcademica")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link> -->

            <router-link
                class="black--text text--accent-4"
                to="Rol"
                active-class="blue--text text--accent-4"
                v-if="persona.Rol == 1"
            >
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>mdi-key</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{
                            $t("nav.Rol")
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </router-link>

            


        <v-list-group>
      <template v-slot:activator>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-book-open</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Malla Curricular") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <router-link
        class="black--text text--accent-4"
        to="Presentacion"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Presentación") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloUno"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo I: Referentes Organizacionales") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloDos"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo II: Referentes Institucionales") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloTres"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo III: Referentes Profesionales/Laborales") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloCuatro"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo IV: Referentes Disciplinarios o Científicos") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloCinco"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo V: Fundamentos Curriculares") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloSeis"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo VI: Perfil Profesional") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloSiete"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo VII: Estructura Curricular Profesional") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloOcho"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo VIII: Políticas de Admisión y Graduación") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloNueve"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo IX: Investigación") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloDiez"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo X: Interacción Social y Extensión Universitaria") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloOnce"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo XI: Implementación del Proyecto Curricular") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloDoce"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo XII: Evaluación Curricular") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="CapituloTrece"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Capítulo XIII: Reglamentos") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="Anexos"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Anexos") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        class="black--text text--accent-4"
        to="Validacion"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-check-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t("Validacion") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>

      <router-link
        v-if="persona.Rol == 1 || persona.Rol == 4"
        class="black--text text--accent-4"
        to="Imprimir"
        active-class="blue--text text--accent-4"
      >
        <v-list-item>
          <v-list-item-icon>
            <v-icon>mdi-file</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title :style="{ whiteSpace: 'normal' }">{{ $t("Imprimir Documento") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
      

    </v-list-group>



    </v-list>
</template>





<script>
export default {
    name: "MenuVuetify",
    data() {
        return {
            msg: "MenuVuetify",
            persona:{},
        };
    },

    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
          this.persona = persona;
            this.getUnidadAcademica();
        }
    }
};
</script>