import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PensumsPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PensumsPage",
            ss: ss,
            ajax: {
                "url": ss.indexArchivoCapitulo7(),
                headers: ss.getToken(),
                data: function (d) {
                    d.idEspecialidad = 0;
                    d.idUnidadAcademica = 0;
                    d.tipo = 0;
                }
            },
            columns: [
                { data: 'idMateria', name: 'idMateria', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'UnidadAcademica', name: 'UnidadAcademica', title: 'Unidad UnidadAcademica' },
                { data: 'Especialidad', name: 'Especialidad', title: 'Unidad Especialidad' },
                { data: 'Materia', name: 'Materia', title: 'Materia' },
                { data: 'Sigla', name: 'Sigla', title: 'Sigla' },
                { data: 'TipoMateria', name: 'TipoMateria', title: 'Tipo Materia' },
                { data: 'tipo', name: 'tipo', title: 'tipo' },
                //{ data: 'Periodo', name: 'Periodo', title: 'Periodo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            seccionValue: 0,
            onclickCompetencia: 0,
            onclickCriterios: 0,
            onclickContenidos: 0,
            idMateria: 0,
            rols: [],
            pensums: [],
            materias: [],
            cargasHorarias: [],
            materiaRequisitos: [],
            bibliografias: [],
            unidadAcademicas: [],
            materiaCriterios: [],
            contenidoAnalitco: [],
            competenciasInstrumentales:[],
            competenciasArea:[],
            competenciasEspecificas:[],
            cargaH:{},
            especialidadsaga:{},
            especialidadesSAGA:[],
            tipoMateriaRequisito:[
                {value:'SUB-SECUENTE'},
                {value:'PRECEDENTE'}
            ],

            unidadesDidacticas: {},
            bibliografia: {},
            materiaCompetenciaSelect: {},
            competenciaInstrumental:{},
            buscarSaga: {},
            competenciaGeneral:{},
            contenidoA: {},
            criterio:{},
            criterioSeleccionado:{},
            unidadDidactica:{},
            pensumShow:{},
            materiaPropuesta: {},
            materiaCompetencia: {},
            materiaRequisito: {},
            justificacion: {},
            cargaHorariaTeoria: {},
            cargaHorariaLaboratorio: {},
            cargaHoraria: {},
            persona: {},
            competencia: {},
            creditos: 0,
            isLoading: false,
            isLoadingFile: false,
            isPropuesta: false,
            errorBag: {},
            password: {},

            materia: {},
            materia2: {}
        };
    },

    computed: {
        totalHoras() {
            return this.cargasHorarias.reduce((sum, ch) => sum + (parseInt(ch.Horas) || 0), 0);
        },

    },

    methods: {
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        getPensums() {
            this.ss.listPensums().then(
                (result) => {
                    let response = result.data;
                    let data = response.data;
                    this.pensums = data;
                    console.log(this.pensums);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        saveMateriaCompetencia() {
            
            this.materiaCompetencia.idMateria = this.materia.idMateria;
            console.log(this.materiaCompetencia);
            this.ss.storeMateriaCompetencia(this.materiaCompetencia).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.materiaCompetencia = response.data;
                        console.log(this.materiaCompetencia);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                    }    
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });  
        },

        saveMateriaCriterioDesempenio() {
            this.criterio.MateriaCompetencia_id = this.materiaCompetencia.id;
            let criterioArreglado = this.criterio.DescripcionCriterio.replace(/\n/g, ' ');
            this.criterio.DescripcionCriterio = criterioArreglado;

            console.log(this.criterio);
            this.ss.storeMateriaCriterios(this.criterio).then(
                (result) => {
                    let response = result.data;
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.criterio= {};
                    this.listMateriaCriterios(this.materia.idMateria);
                    this.$refs['frm-criterio-desempenio'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }); 
        },

        deleteCriterioDesempenio(item) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyMateriaCriterios(item)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.listMateriaCriterios(this.materia.idMateria);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        storeCriteriosDesempeño(item) {
            if(item){
                this.criterio = item;
            }
            else{
                this.criterio = {};
            }
            this.$refs['frm-criterio-desempenio'].show();
        },

        saveJustificacion() {
            if(this.materia2.Justificacion){
                //let justificacionArreglado = this.materia.Justificacion.replace(/\n/g,' ');
                let justificacionArreglado = this.materia2.Justificacion;
                this.materia2.Justificacion = justificacionArreglado;
            }
            console.log(this.materia2);
            this.ss.storeMateria(this.materia2).then(
                (result) => {
                    let response = result.data;
                    this.materia2 = response.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-justificacion'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        storeMateriaRequisito(){
            this.materiaRequisito.idMateria = this.materia.idMateria;
            if(this.persona.idEspecialidad){
                console.log("esta es la funcion 2");
                this.listMaterias(this.persona.idEspecialidad);
            }
            else{
                this.listMaterias(this.materia.idEspecialidad);
            }
            this.$refs['frm-materia-requisito'].show(); 

        },

        listMaterias(id){
            this.materias=[];
            this.ss.listMateria(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.data){
                        this.materias = response.data;
                        console.log("funcion correcta");
                        console.log(this.materias);
                    }
                    else{
                        console.log("fallo en el id");
                        this.materias=[];
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        destroyMateriaRequistio(item){
            this.ss.destroyRequisitos(item).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                    this.getMateriaRequisito(this.materia.idMateria);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        listUnidadDidactica(id) {
            this.ss.listUnidadDidactica(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.data){
                        this.unidadesDidacticas = response.data[0];
                        this.listContenidoAnalitico(this.unidadesDidacticas.id);
                    }
                    else{
                        this.unidadesDidacticas = null;
                    }
                    console.log(this.unidadesDidacticas);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        listContenidoAnalitico(id) {
            this.ss.listContenidoAnalitico(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.contenidoAnalitco = response.data;
                    }
                    else{
                        this.contenidoAnalitco = [];
                    }
                    console.log(this.contenidoAnalitco);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        saveMateriaUnidadDidactica() {
            this.unidadDidactica.MateriaCriterioDesempeno_id = this.criterioSeleccionado.id;
            let unidad = this.unidadDidactica.UnidadDidactica.replace(/\n/g, ' ');
            this.unidadDidactica.UnidadDidactica = unidad;
            console.log(this.unidadDidactica);
            this.ss.storeUnidadDidactica(this.unidadDidactica).then(
                (result) => {
                    let response = result.data;
                    console.log(response.data);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.listUnidadDidactica(this.criterioSeleccionado.id);
                    console.log(response);
                    this.$refs['frm-unidad-didactica'].hide();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        criterioSeleccion(item){
            this.criterioSeleccionado = item;
            this.contenidoAnalitco=[];
            this.listUnidadDidactica(this.criterioSeleccionado.id);
        },

        storeUnidadDidactica(item) {
            if(item){
                this.unidadDidactica = item;
            }
            else{
                this.unidadDidactica = {};
            }
            this.$refs['frm-unidad-didactica'].show();
        },

        deleteUnidadDidactica(item) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyUnidadDidactica(item)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.listUnidadDidactica(this.criterioSeleccionado.id);
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        deleteContenidoAnalitico(item) {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyContenidoAnalitico(item)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.listUnidadDidactica(this.criterioSeleccionado.id);
                            })
                            .catch(error => {
                                console.log(error);
                            }) 
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },

        async saveContenidoAnalitico() {
            await this.listUnidadDidactica(this.criterioSeleccionado.id);

            let contenidoAnaliticoSave={};

            if(this.contenidoA.ContenidoAnalitico.includes('//')){

                let contenidos = this.contenidoA.ContenidoAnalitico.split('\n');

            for (let index = 0; index < contenidos.length; index++) {
                if(contenidos[index].includes('//')){
                    var conteo = (contenidos[index].match(/\/\//g) || []).length;

                    if (conteo > 1) {
                        alert('Más de una ocurrencia de "//" detectada');
                        return;
                    }else{
                        continue;
                    }
                }else{
                    if(contenidos[index] === ""){
                        contenidos.splice(index, 1);
                    }else{
                        alert('debe incluir los separadores "//" entre el codigo y el contenido analitico.');
                        return;
                    }
                }
            }



            for (let index = 0; index < contenidos.length; index++) {
                
                let separado = contenidos[index].split('//');
                contenidoAnaliticoSave.Nivel = separado[0];
                contenidoAnaliticoSave.ContenidoAnalitico = separado[1];
                contenidoAnaliticoSave.MateriaContenido_id = this.unidadesDidacticas.id;

                await new Promise(resolve => setTimeout(resolve, 500));

                this.ss.storeContenidoAnalitico(contenidoAnaliticoSave).then(
                    (result) => {
                        let response = result.data;
                        console.log(response.data);
                        this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        )
                    })
                    .catch((error) => {
                        this.errorBag = error.response.data.errors;
                        this.$bvToast.toast(
                            'Problema al Guardar el Registro, favor verificar los Datos',
                            {
                                title: 'Error',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                    });

            }

            this.listUnidadDidactica(this.criterioSeleccionado.id);
            this.$refs['frm-contenido-analitico'].hide();

            }else{
                alert('debe incluir los separadores "//" entre el codigo y el contenido analitico.');
            }
        },

        storeBibliografia(item){
            if(item){
                this.bibliografia = item;
            }else{
                this.bibliografia = {};
            }

            this.$refs['frm-materia-bibliografia'].show();

        },

        destroyBibliografia(id){
            this.ss.destroyBibliografia(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.listBibliografia(this.materia.idMateria);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });

        },

        savebibliografia(){
            this.bibliografia.idMateria = this.materia.idMateria;
            console.log(this.bibliografia);
            this.ss.storeBibliografia(this.bibliografia).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    if(response.acc == 'creado'){
                        this.listBibliografia(this.materia.idMateria);
                    }
                    this.$refs['frm-materia-bibliografia'].hide();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        cancelStoreBibliografia(){
            this.bibliografia = {};
            this.$refs['frm-materia-bibliografia'].hide();
        },

        storeContenidoAnalitico(item) {
            if(item){
                this.contenidoA = item;
            }
            else{
                this.contenidoA = {};
            }
            this.$refs['frm-contenido-analitico'].show();
        },

        cancelStoreMateriaRequisito(){
            this.materiaRequisito = {};
            this.$refs['frm-materia-requisito'].hide(); 

        },

        cancelshow(){
            this.$refs['view-pensum'].hide();
        },

        cancelContenidoAnalitico() {
            this.contenidoA = {};
            this.$refs['frm-contenido-analitico'].hide();
        },

        cancelStoreUnidadDidactica() {
            this.unidadDidactica = {};
            this.$refs['frm-unidad-didactica'].hide();
        },

        showUnidadDidactica() {
            this.$refs['frm-Unidad-Didactica-Contenido-Analitico'].show();
        },

        cancelStoreCriterio() {
            this.criterio = {};
            this.$refs['frm-criterio-desempenio'].hide();
        },

        editMarcoReferencial() {
            this.$refs['frm-marco-referencial'].show();
        },

        showCargaHorariaView() {
            this.$refs['view-carga-horaria'].show();
        },

        cancelCargaHorariaView() {
            this.$refs['view-carga-horaria'].hide();
        },

        storeCargaHoraria(item){
            this.cargaH = item;
            this.$refs['frm-carga-horaria'].show();
            this.$refs['view-carga-horaria'].hide();
        },
        storeCreditos(){
            this.cargaH.idMateria = this.materia.idMateria;
            this.cargaH.idEspecialidad = this.materia.idEspecialidad;
            this.cargaH.Gestion = this.materia.Gestion;
            this.$refs['frm-creditos'].show();
        },
        cancelStoreCreditos(){
            this.cargaH = {};
            this.$refs['frm-creditos'].hide();
        },
        cancelStoreCargaHoraria(){
            this.cargaH = {};
            this.$refs['frm-carga-horaria'].hide();
        },
        saveCargaHorariaT(){

            if(!this.cargaH.idMateria){
                this.cargaH.idMateria = this.materia.idMateria;
                this.cargaH.idEspecialidad = this.materia.idEspecialidad;
                this.cargaH.Gestion = this.materia.Gestion;
                this.cargaH.accion = 0;

            }else{
                this.cargaH.accion = 1;
            }

            this.ss.storeMateriaCargaHoraria(this.cargaH).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                }
            ).catch(error => {
                console.log(error);
            });

            this.showCargaHoraria(this.materia);
            this.cargaH = {};
            this.$refs['frm-carga-horaria'].hide();

        },


        saveMateriaRequisito(){
            console.log(this.materiaRequisito);
            this.ss.storeRequisitos(this.materiaRequisito).then(
                (result) => {
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    this.getMateriaRequisito(this.materia.idMateria);
                    this.$refs['frm-materia-requisito'].hide(); 
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }); 
        },

        saveCreditosMateria(){


            if(Array.isArray(this.cargasHorarias) && this.cargasHorarias.length > 0){

                for (let index = 0; index < this.cargasHorarias.length; index++) {
                    console.log(this.cargasHorarias[index]);
                    this.cargasHorarias[index].Creditos = this.cargaH.Creditos;
                    this.ss.storeMateriaCargaHoraria(this.cargasHorarias[index]).then(
                        (result) => {
                            let response = result.data;
                            console.log(response);
                            
                        }
                    ).catch(error => {
                        console.log(error);
                    });
                }

                this.showCargaHoraria(this.materia);
                this.cargaH = {};
                this.$refs['frm-creditos'].hide();
            }

        },

        showPensumMateria(id){
            this.materia={};
            this.materia2={};
            this.ss.showPensum(id).then(
                (result) => {
                    let response = result.data;
                    this.materia = response.data;
                    //this.materia2 = this.materia;
                    console.log("Metodo correcto");
                    console.log(this.materia.idMateria);
                    this.showMateria(this.materia.idMateria);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showMateria(id) {
            //this.materia={};
            this.ss.showMateria(id).then(
                (result) => {
                    let response = result.data;
                    this.materia2 = response.data;
                    //this.materia2 = this.materia;
                    console.log(this.materia2);
                    console.log("modificacion");
                    //console.log(this.materia2);
                    this.showMateriaCompetencia(this.materia.idMateria);
                    this.showCompetenciaGeneral(this.materia.idEspecialidad);
                    this.listBibliografia(this.materia.idMateria);
                    this.getMateriaRequisito(this.materia.idMateria);
                    this.showPensum();
                    //this.materia = response.data; 
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showPensumMateria2(id){
            this.materia={};
            this.materia2={};
            this.ss.showPensum(id).then(
                (result) => {
                    let response = result.data;
                    this.materia = response.data;
                    //this.materia2 = this.materia;
                    console.log("Metodo correcto");
                    console.log(this.materia);
                    this.showCargaHoraria(this.materia);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showPensum() {
            this.$refs['view-pensum'].show();
        },

        getMateriaRequisito(id) {
            this.ss.listRequisitos(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.materiaRequisitos = response.data;
                    }else{
                        this.materiaRequisitos = [];
                        console.log(this.materiaRequisitos);
                    }
                }
            ).catch(error => {
                console.log(error);
            });
        },

        listBibliografia(id){
            this.ss.listBibliografia(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.bibliografias = response.data;
                    }else{
                        this.bibliografias = [];
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showCompetenciaArea(id){
            this.ss.showCompetenciaArea(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    this.competenciasArea = response.data;
                    console.log(this.competenciasArea);
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showCompetenciaGeneral(id){
            this.isLoading = true;
            this.ss.showCompetenciaGeneral(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.competenciaGeneral = response.data[0];
                        console.log(this.competenciaGeneral);
                        this.showCompetenciasInstrumentales(this.competenciaGeneral.id);
                        this.getCompetenciasEspecificas(this.pensum.idEspecialidad)

                    }
                    else{
                        this.competenciaGeneral={};
                        this.competenciasArea={};
                        this.competenciasInstrumentales=[];
                        this.showEspecialidad(id); 
                    }
                    this.isLoading = false;
                    this.$refs['view-especialidad'].show();
                }
            ).catch(error => {
                console.log(error);
            });
        },

        getCompetenciasEspecificas(id) {
            this.ss.listCompetenciaEspecifica(id).then(
                (result) => {
                    let response = result.data;
                    this.competenciasEspecificas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showCompetenciasInstrumentales(id){
            this.isLoading = true;
            this.ss.showCompetenciaInstrumentales(id).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    if(response.success){
                        this.competenciasInstrumentales = response.data;
                        console.log(this.competenciasInstrumentales);
                    }
                    else{
                        this.competenciasInstrumentales=[];
                    }
                    this.isLoading = false;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        showMateriaCompetencia(id) {
            this.materiaCompetencia={};
            this.ss.showMateriaCompetencia(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.materiaCompetencia = response.data;
                        console.log(this.materiaCompetencia);
                        this.listMateriaCriterios(this.materiaCompetencia.id);
                    }
                    else{
                        this.materiaCompetencia = {};
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        listMateriaCriterios(id) {
            this.materiaCriterios = [];
            this.ss.listMateriaCriterios(id).then(
                (result) => {
                    let response = result.data;
                    if(response.data){
                        this.materiaCriterios = response.data;
                    }
                    else{
                        this.materiaCriterios = [];
                    }
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showCargaHoraria(id){
            this.isLoading=true;
            this.cargasHorarias={};
            this.ss.showMateriaCargaHoraria(id).then(
                (result) => {
                    let response = result.data;
                    console.log("carga horaria",response);
                    this.cargasHorarias = response.data;
                    console.log(this.cargasHorarias);
                    this.showCargaHorariaView();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        async getEspecialidad2(){
            this.isLoading=true;
        if(this.especialidadsaga.idEspecialidad || this.especialidadsaga.idUnidadAcademica || this.especialidadsaga.tipo){
            let idEspecialidad = this.especialidadsaga.idEspecialidad ?? 0;
            let idUnidadAcademica = this.especialidadsaga.idUnidadAcademica ?? 0;
            let tipo = this.especialidadsaga.tipo ?? 0;
            this.ajax.data = function(d){
                d.idEspecialidad = idEspecialidad;
                d.idUnidadAcademica = idUnidadAcademica;
                d.tipo = tipo;
            }
        }
        else{
            let idEspecialidad = this.persona.idEspecialidad;
            let idUnidadAcademica = this.persona.idUnidadAcademica;
            let tipo = '2024';
            this.ajax.data = function(d){
                d.idEspecialidad = idEspecialidad;
                d.idUnidadAcademica = idUnidadAcademica;
                d.tipo = tipo;
            }
        }
        await new Promise(resolve => setTimeout(resolve, 2000));
    
        this.$refs['datatable-Materia'].reloadAjax(this.ajax);
        this.isLoading=false;
        }, 

        getAreaFormacion(){
            if(this.materia2.CicloDeFormacion == 'BASICAS'){
                this.materia2.AreaDeConocimiento = 'BASICAS';
                return;
            }

            if(this.materia2.CicloDeFormacion == 'INSTRUMENTAL'){
                this.materia2.AreaDeConocimiento = 'INSTRUMENTAL';
                return;
            }

            this.showCompetenciaArea(this.competenciaGeneral.idEspecialidad);

        },

        getEspecialidades(){
            this.ss.listEspecialidad().then(
               (result) => {
                   let response = result.data;
                   console.log(response);
                   this.especialidadesSAGA = response;
               }
           ).catch(error => {
               console.log(error);
           });
        },

        generarReporteMasivo(){
            this.isLoading= true;
            let timerInterval;
            this.$swal.fire({
                title: "Generando el archivo!",
                html: "El proceso podria tardar <b></b> milliseconds.",
                timer: 100000,
                timerProgressBar: true,
                didOpen: () => {
                    this.$swal.showLoading();
                    const timer = this.$swal.getPopup().querySelector("b");
                    timerInterval = setInterval(() => {
                        timer.textContent = `${this.$swal.getTimerLeft()}`;
                    }, 100);
                },
                willClose: () => {
                    clearInterval(timerInterval);
                }
            }).then((result) => {
                if (result.dismiss === this.$swal.DismissReason.timer) {
                    console.log("I was closed by the timer");
                }
            });

            // Llamar al método y manejar la respuesta
            this.ss.pdfContenidoMasivo(this.persona.idEspecialidad).then(
                (result) => {
                    let response = result.data;
                    console.log(response);
                    window.open(response.url, '_blank');

                    // Cerrar el alert
                    this.isLoading=false;
                    this.$swal.close();
                }
            ).catch(error => {
                console.log(error);
                this.isLoading = false;

                // Cerrar el alert en caso de error
                this.$swal.close();
            });
        },


        showReporte(){

            if(this.persona.idEspecialidad){
                let idEspecialidad = this.persona.idEspecialidad;
                let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Materia/pdfCargaHoraria?id=${idEspecialidad}`;
                console.log(url);
                window.open(url, '_blank');
                this.persona.idEspecialidad=null;
                this.$refs['view-especialidad-select'].hide();
            }
            else{
                this.$refs['view-especialidad-select'].show();
            }
        },


        showReporte2(){

            /* if(this.persona.idEspecialidad){
                let idEspecialidad = this.persona.idEspecialidad;
                let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Materia/pdfCargaHoraria?id=${idEspecialidad}`;
                console.log(url);
                window.open(url, '_blank');
                this.persona.idEspecialidad=null;
                this.$refs['view-especialidad-select'].hide();
            }
            else{
                this.$refs['view-especialidad-select'].show();
            } */

                this.$refs['view-especialidad2-select'].show();

        },


        showReporteContenido(){
            let idMateria = this.materia.id;
            console.log(this.materia);
            let url = `${process.env.VUE_APP_MAIN_SERVICE}api/Materia/pdfContenido?id=${idMateria}`;
            console.log(url);
            window.open(url, '_blank');
    },

        draw() {
            window.$('.btn-datatable-Materia').on('click', (evt) => {
                let id = window.$(evt.target)[0].getAttribute('id');
                if(id){
                    this.isLoading=true;
                    this.showPensumMateria2(id);
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });

            window.$('.btn-datatable-Materia2').on('click', (evt) => {
                let id = window.$(evt.target)[0].getAttribute('id');
                if(id){
                    this.id = id;
                    this.cargasHorarias= [];
                    this.showPensumMateria(id);
                }else{
                    this.$bvToast.toast(
                        'Ocurrio un problema inesperado, intente nuevamente.',
                        {
                            title: 'Oops',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                }
            });

            
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
            this.$router.push('/Login');
        } else {
            this.persona = persona;
            this.getEspecialidad2();
            this.getEspecialidades();
        }
    }
};
